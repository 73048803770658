import * as React from "react"
import styled, { keyframes } from "styled-components"
import "@fontsource/merriweather/400.css"
import "@fontsource/merriweather/700.css"
import "@fontsource/staatliches"
import { graphql, Link } from 'gatsby'
import Pill from "../components/pill"
import ProjectActionBar from "../components/project-action-bar";
import SHLHeader from "../components/SHL-header";
import Badge from "../components/badge";

// styles
const pageStyles = {
    fontFamily: "Roboto, sans-serif, serif",
    width: "auto",
    height: "auto",
    minHeight: "100vh",
    padding: "auto",
}
const contentStyles = {
    position: "relative",
    maxWidth: "768px",
    padding: "16px",
    margin: "auto",
    color: "#d8d8d8",
    backgroundColor: "#181818cc",
    marginBottom: "16px",
    borderRadius: "8px"
}
const FadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: none;
  }
`
const StyledLink = styled(props => <Link {...props} />)`
  display: inline-block;
  font-size: 1.2em;
  width: fit-content;
  color: #ff008c;
  transition: color 0.25s;
  :hover {
    color: #ffffff;
  }
  font-family: Merriweather, "Noto Serif TC", serif;
`
const Header2 = styled.h2`
  font-family: Merriweather, "Noto Serif TC", serif;
  font-size: 2em;
  margin: 10px 0 0 0;
  color: #ffffff;
  hyphens: auto;
`
const Date = styled.div`
  color: #888888;
  font-family: Merriweather, "Noto Serif TC", serif;
  margin-bottom: 12px;
  font-size: 1.2em;
`
const Summary = styled.div`
  line-height: 1.5;
  font-size: 1.2em;
  font-family: Merriweather, "Noto Serif TC", serif;
  @media (min-width: 600px){
    text-align: justify;
    text-justify: newspaper;
  }
`
const TagContainer = styled.div`
  margin: 12px 0;
`
const Content = styled.div`
  font-family: Merriweather, "Noto Serif TC", serif;
  h1, h2, h3, h4, h5 {
    color: #ffffff;
  }
  p {
    hyphens: auto;
    @media (min-width: 600px){
      text-align: justify;
      text-justify: newspaper;
    }
  }
  li{
    hyphens: auto;
    line-height: 1.8;
    margin: 0.25em 0;
  }
  a {
    color: #ff008c;
    transition: color 0.25s;
    height: 100%;
    :hover {
      color: white;
    }
  }
  img, iframe {
    border-radius: 8px;
  }
`
const BottomNav = styled.div`
  display: flex;
  @media (max-width: 600px){
    flex-flow: column;
  }
  font-family: Merriweather, "Noto Serif TC", serif;
  color: #d8d8d8;
  font-size: 1em;
  width: 100%;
  margin-top: 3em;
`
const BottomNavNext = styled.div`
  position: relative;
  flex: 1;
  display: inline-block;
  @media (max-width: 600px){
    text-align: center;
    margin-bottom: 1em;
  }
`
const BottomNavMid = styled.div`
  flex: 1;
  display: inline-block;
  @media (max-width: 600px){
    display: none;
  }
  text-align: center;
`
const BottomNavPrev = styled.div`
  position: relative;
  flex: 1;
  display: inline-block;
  text-align: right;
  @media (max-width: 600px){
    text-align: center;
  }
`
const BottomNavTag = styled.span`
  display: block;
  font-size: 0.9em;
  @media (min-width: 600px){
    position: absolute;
    right: 0;
    left: 0;
  }
  top: -1.3em;
`
const TableOfContents = styled.div`
  position: fixed;
  left: 20px;
  bottom: 20px;
  font-family: Merriweather, "Noto Serif TC", serif;
  p {
    margin: 0;
  }
  li{
    margin: 0.5em 0;
  }
  li::marker{
    color: #ff008c;
  }
  ul{
    padding-left: 1em;
  }
  ul > li > ul {
    font-size: 0.8em;
  }
`
const FullContentWrapper = styled.div`
  animation: 0.25s ease-out 0s 1 FadeInDown;
`
// markup
const PortfolioProjectPage = ({data}) => {
    const node = data.self.nodes[0];
    return (
        <main style={pageStyles}>
            <div style={contentStyles}>
                <title>{node.frontmatter.title + " | Portfolio | SH Liu | rarakasm"}</title>
                <SHLHeader subtitle="Portfolio" subtitleLink="/portfolio"/>
                {/*<TableOfContents dangerouslySetInnerHTML={{__html: node.tableOfContents}}/>*/}
                <FullContentWrapper>
                    <Header2>{node.frontmatter.title}</Header2>
                    {(node.frontmatter.badge && (
                        <Badge tag={node.frontmatter.badge}/>
                    ))}
                    <Date>{node.frontmatter.date}</Date>
                    <Summary>{node.frontmatter.summary}</Summary>
                    <TagContainer>
                        {
                            node.frontmatter.tags?.map((tag) =>
                                <Pill tag={tag}/>
                            )
                        }
                    </TagContainer>
                    <ProjectActionBar data={node.frontmatter}/>
                    <hr/>
                    <Content dangerouslySetInnerHTML={{__html: node.html}}/>
                    <hr/>
                    <ProjectActionBar data={node.frontmatter} rightAligned />
                    <BottomNav>
                        <BottomNavNext>
                            {(data.next.nodes.length > 0 && (
                                <>
                                    <BottomNavTag>&lt; Newer</BottomNavTag>
                                    <StyledLink to={"/portfolio/" + data.next.nodes[0].frontmatter.path}>{data.next.nodes[0].frontmatter.title}</StyledLink>
                                </>
                            ))}
                        </BottomNavNext>
                        <BottomNavMid>
                            <StyledLink to={"/portfolio/#" + node.frontmatter.path}>Back to portfolio</StyledLink>
                        </BottomNavMid>
                        <BottomNavPrev>
                            {(data.prev.nodes.length > 0 && (
                                <>
                                    <BottomNavTag>Older &gt;</BottomNavTag>
                                    <StyledLink to={"/portfolio/" + data.prev.nodes[0].frontmatter.path}>{data.prev.nodes[0].frontmatter.title}</StyledLink>
                                </>
                            ))}
                        </BottomNavPrev>
                    </BottomNav>
                </FullContentWrapper>
            </div>
        </main>
    )
}

export default PortfolioProjectPage


export const query = graphql`
    query MyQuery($id: String!, $next: String, $prev: String) {
      self: allMarkdownRemark(filter: {id: {eq: $id}}) {
        nodes {
          id
          frontmatter {
            path
            date(formatString: "YYYY")
            link
            release
            repo
            summary
            tags
            thumbnail
            title
            video
            badge
          }
          html
          tableOfContents(pathToSlugField: "frontmatter.path", absolute: false)
        }
      }
      next: allMarkdownRemark(filter: {id: {eq: $next}}) {
        nodes {
          id
          frontmatter {
            title
            path
          }
        }
      }
      prev: allMarkdownRemark(filter: {id: {eq: $prev}}) {
        nodes {
          id
          frontmatter {
            title
            path
          }
        }
      }
    }
`
